@import "../../App.scss";

.eventdetail{
    background-color: #f7f7f7;
    h1{
        font-size: 2.5em;
    }
    h2{
        font-size: 1.2em;
        font-weight: 400;
    }
    .eventDetaildiv{
        margin:2% 5%;
        background: linear-gradient(to bottom right, $extraLightPink, $lightPink, $pink);
        padding:2% 5%;
        border-radius: 10px;
    }
    .row{
        margin-top: 3%;
    }
    .status-div{
        margin-top: 2%;
        .status-active{
            margin-right: 3%;
            padding:.5% 2%;
        }
        .status-red{
            padding:.5% 2%;
        }
    }
    .timediv{
        margin:3% 0;
        p{
            background-color: #23014411;
            padding:2%;
            margin:auto;
            border-radius: 5px;
        }
    }
    .detailbutton{
        padding:.5% 5%;
        margin:2%;
    }
}


.live-stream-div{
height: 50vh;
width: 50vw;
display: block;
margin: 5% auto;
border: 5px solid #5a1e96;
border-radius: 5px;
}

@media (max-width: 992px) {

}
@media (max-width: 768px) {


}
@media (max-width: 576px) {
 .eventdetail{
     .timediv{
         margin:5% 0;
         p{
             margin:3% 0;
         }
     }
 }
}