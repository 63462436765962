$purple:#5a1e96;
$pink:#eedeff;
$lightPink:#faf5ff;
$extraLightPink:#fbf7ff;
$white:#f7f7f7;
$grey:#a1a1a1;
$green:#13a100;

$primary-font:'Montserrat', sans-serif;
//$secondary-font:'Roboto', sans-serif;
// $primary-font:'Gilda Display', serif;
 $secondary-font: 'Poppins', sans-serif;
h1,h2,h3,h5{
  font-family: $primary-font;

}
p{
  font-family: $secondary-font;
  font-weight: 400;
}

.submit{
  padding:1.8% 8%; 
  border-radius: 50px;
  outline: none;
  background-color: #5a1e96;
  border:none;
  font-family: $primary-font;
  color: white;
  font-weight: 600;
}
.submit:hover{
  cursor: pointer;
}
.red-status{
  color: #ff8383;
  font-weight: bolder;
}
.green-status{
  color: #5fd46c;
  font-weight: bolder;
}

.btn-purple{
    background-color: $purple;
    color:white;
    border-radius: 30px;
    border: none;
    font-size: 1em;
}
.btn-purple:hover{
    cursor: pointer;
}

.btn-purple-outline{
    background-color: white;
    color:$purple;
    border: 1px solid $purple;
    border-radius: 30px;
    font-size: 1em;
}
.btn-purple-outline:hover{
    cursor: pointer;
}

.padded-container{
    padding: 0 7%;
}
.alignright{
  text-align: right;
}
.error{
  color: rgb(230, 0, 0);
}
.greycolor{
  color:#a1a1a1;
}

.cursor-pointer:hover{
  cursor: pointer;
}

// .label{
//   display: block;
//   text-align: left;
//   font-weight: bolder;
// }
// .input{
//   width: 100%;
//   padding:3%;
//   border-radius: 10px;
//   border: 2px solid #ccca;
//   outline: none;
// }
// .input:focus{
//   border: 2px solid $purple;
//   outline: none;
// }

.alert{
  position: absolute;
  bottom: 0;
  z-index: 5;
  left: 50%;
}

::-webkit-scrollbar{
  width:5px !important;
}

::-webkit-scrollbar-track{
  background:transparent !important;
}


::-webkit-scrollbar-thumb{
  background:rgb(235, 235, 235) !important;
  opacity:0.2;
  border-radius:25px
}

::-webkit-scrollbar-thumb:hover{
  background:#5a1e96 !important;
}


@media (max-width: 992px) {

}
@media (max-width: 768px) {


}
@media (max-width: 576px) {
  .alert{
    position: absolute;
    bottom: 0;
    z-index: 5;
    left: 0%;
  }
  
}