@import "../../App.scss";

.dashboard-container{
overflow-y:scroll;
height: 100vh;
h1{
  font-weight: 300;
  color: $purple;
}
.dashboard-heading{
  margin-top: 2%;
}
.searchdiv{
  margin:2% 25%;
  .input{
    padding:1% 2%;
    outline: none;
    border: none;
    box-shadow: .1px .1px 5px #cccf;
    border-radius: 20px;
    background-color: rgba(204, 204, 204, 0.212);

  }
  input{
    width: 90%;
    border:none;
    background-color: transparent;
    font-size: 1.2em;
  }
  input::placeholder{
    color: rgb(158, 158, 158);
  }
  input:focus{
    outline: none;
  }
}

}

.parentofcards{
  margin: 2%;
  .cardhead{
    margin:2%;
    background: linear-gradient(to bottom right, $extraLightPink, $lightPink, $pink);
    border-radius: 10px;
    padding:1% 3%;
    h2{
      color: $purple;
      font-weight: 600;
      font-size: 1.9em;
    }
    
    .subs{
      color: #a1a1a1;
    }
    .chipdiv{
      margin-bottom: 3%;
      .private{
        background-color:#00d819;
        color: white;
        padding:1% 3%;
        border-radius: 5px;
        margin-right: 3%;
      }
      
    }
    .descriptioncontainer{
      h5{
        margin-bottom: 0;
      }
      p{
        margin-top: 1%;
      }
    }
    .timecontainer{
      margin:5% 1%;
      background-color: #23014411;
      color: black;
      padding:3%;
      border-radius: 10px;
      .starts{
      }
    }
    .start{
      color: #009211;
    }
    .end{
      color:#b60000;
    }
    .cardbuttondiv{
      margin-top: 5%;
      margin-bottom: 2%;
      .cardbutton{
        padding:1.5% 5%;
      }
    }
    transition: .2s;
  }
  .cardhead:hover{
    cursor: pointer;
    background: #ebd9ff;
    transform: scale(1.05);
  }


  .cardheadgrey{
    margin:2%;
    background: linear-gradient(to bottom right, #e7e7e7, #eeeeee, #f7f7f7);
    border-radius: 5px;
    padding:1% 3%;
    h2{
      color: $purple;
      font-weight: 700;
    }
    .subs{
      font-weight: bold;
      color: $purple;
    }
    .chipdiv{
      margin-bottom: 3%;
      .private{
        background-color:#00d819;
        color: white;
        padding:1% 3%;
        border-radius: 5px;
        margin-right: 3%;
      }
      
    }
    .start{
      color: #009211;
    }
    .end{
      color:#b60000;
    }
    .cardbuttondiv{
      margin-top: 5%;
      margin-bottom: 2%;
      .cardbutton{
        padding:1.5% 5%;
      }
    }
    transition: .2s;
  }
  .cardheadgrey:hover{
    cursor: pointer;
  }
 
}
.status-red{
  background-color:#ff5e5e;
  color: white;
  padding:1% 3%;
  border-radius: 5px;
}
.status-active{
  background-color:#00d819;
  color: white;
  padding:1% 3%;
  border-radius: 5px;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  .iconbutton{
    display: none;
  }
  @media (max-width: 992px) {

  }
  @media (max-width: 768px) {
  
  
  }
  @media (max-width: 576px) {
    .dashboard-heading{
      margin-top: 2%;
      margin-bottom: 8%;
    }
    
    .iconbutton{
      display:inline-block;
    }
    .parentofcards{
      .cardhead{
        padding:7%; 
      }
    }
  }