@import "../../App.scss";

.privacypolicy{
    text-align: center;
    h1{
        color: #5a1e96;
    }
    p{
        margin: 0 10%;
        line-height: 2;
    }
}