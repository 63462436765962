@import "../../../App.scss";

@import "../../../App.scss";


.reminder-container{
.shadow-sm{
    background: linear-gradient(to right, $pink,$lightPink);

}
}