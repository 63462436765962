@import "../App.scss";

.h1{
    text-align: center;
    font-size: 3.5em;
    color:$purple;  

    font-weight: 500;
}

.heading{
    text-align: center;
    font-size: 3.5em;
    color:$purple;
    font-weight: 500;
}
.headingwithpad{
    padding:15% 0 5% 0;
    text-align: center;
    font-size: 3.5em;
    color:$purple;
    font-weight: 500;
}
h2{
    font-weight: 400;
    font-size: 2.3em;
    color: $purple;

}
.firstsection{
    section{
        margin:10% 0;
        h1{
            font-size: 3.5em;
            color: $purple;
            font-weight: 600;
        }
        p{
            line-height: 1.5;
        }
        .row{
            padding:0 5%;
            button{
                padding:1.5% 3%;
                width: 25%;
                margin:0 2%
            }
            
        }
     
    }
}
.maplogo{
    height: 50vh !important;
    width: 50vw !important;
}


.s2{
    .s2-card{
        padding:3%;
        text-align: center;
        background: linear-gradient(to bottom right, $pink, $extraLightPink);
        border-radius: 10px;
       
        .cardimage{
            height: 25vh;
            width: 15vw;
        }
    }
}

.secondsection{
    .heading{
        text-align: center;
        font-size: 3.5em;
        color:$purple;
        margin:5% 0 0 0;
    }
    .row{
        margin:1% 2%;
        .col-xl-6{
            margin: auto;
        }
        h1{
            color:$purple;
        }
        p{
            line-height: 2;
        }
    }
    .svg{
        height: 40vh;
        width: 40vw;
    }
}

.thirdsection{
    padding:0 15%;
    text-align: center;
    .headingt{
        text-align: center;
        font-size: 3.5em;
        font-weight: 500;
        color: $purple;
        margin: 15% 0 2% 0;
    }
    p{
        line-height: 2;
    }

}

.fourthsection{
    .row{
        text-align: center;
        .shadow{
            padding:3%;
            border-radius: 10px;
            background: linear-gradient(to bottom right, $pink, $extraLightPink);
        }
    }
}


@media (max-width: 992px) {

}
@media (max-width: 768px) {


}
@media (max-width: 576px) {
    .firstsection{
        section{
           
            .row{
                padding:0 5%;
                button{
                    padding:1.5% 20%;
                    width: 34%;
                    margin:0 2%
                }
                
            }
    
        }
    }
    .maplogo{
        height: 30vh !important;
        width: 80vw !important;
    }
    .heading{
        font-size: 3em;
    }
    .s2{
        margin: 0 10% !important;
        .s2-card{
            padding:5% 5% 15% 5%;
            text-align: center;
            background: linear-gradient(to bottom right, $pink, $extraLightPink);
            border-radius: 10px;
            margin-bottom: 10%;
            h2{
                font-weight: 400;
                font-size: 2.3em;
                color: $purple;
    
            }
            .cardimage{
                height: 30vh;
                width: 50vw;
            }
        }
    }
    .secondsection{
        text-align: center;
        .svg{
            height: 40vh;
            width: 80vw;
        }
    }
    .fourthsection{
        .row{
            .shadow{
                margin: 10% 5%;
            }
        }
    }
}